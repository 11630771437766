import {AxiosInstance, AxiosResponse} from 'axios'

import {PasswordlessStartResponse} from '../response'
import {Channel, Product} from '../types'

const RESPONSE_OK = 200

export interface PasswordlessStartParams {
  client_id: string
  client_secret: string
  channel: Channel
  mobile_number: string | null
  email: string | null
  product: Product
  request_locale: string
  redirect_uri: string
  country_code: string | null
  reset_password?: boolean
  type: 'code' | 'link'
}

export const passwordlessStart =
  (api: AxiosInstance) =>
  async (params: PasswordlessStartParams): Promise<PasswordlessStartResponse> => {
    try {
      if (!params.client_id) {
        console.error('client_id is not set. Did you load this page directly?')
      }

      const body = {
        ...params
      }

      if (body.country_code === '') {
        if (params.channel === 'email' || params.channel === 'sms') {
          body.country_code = null
        }
      }

      const response = await api.post<{start_token: string}>('/passwordless/start', body, {
        validateStatus: () => true
      })

      if (response.status !== RESPONSE_OK) {
        return {
          type: 'error',
          error: {errorCode: response.status, message: JSON.stringify(response.data)}
        }
      }
      return {type: 'value', value: response.data}
    } catch (error) {
      return {
        type: 'error',
        error: error as {errorCode: number; message: string}
      }
    }
  }

export interface PasswordlessVerifyParams {
  client_id: string
  client_secret: string
  channel: Channel
  username: string
  start_token: string
  otp: string
  scope: string
}

export const passwordlessVerify =
  (api: AxiosInstance) =>
  (params: PasswordlessVerifyParams): Promise<AxiosResponse> => {
    if (!params.client_id) {
      console.error('client_id is not set. Did you load this page directly?')
    }

    const body = {
      ...params
    }

    return api.post('/passwordless/verify', body)
  }
