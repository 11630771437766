import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  ClientConfig,
  createHttpClient,
  createPublicHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends
} from '@hconnect/apiclient'
import {History} from '@hconnect/uikit'
import {applyMiddleware, createStore, Reducer} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction'
import thunk from 'redux-thunk'

import appReducer, {resetEnhancer} from './App.reducer'

// Note: for production we have to use a different auth host
// Note: This is not send over as a parameter. This is directly used by the UI
// component to redirect to the matching auth host.
// authenticatorHost: SELECTED_ENV.authenticatorHost,

const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
const selectedBackend = backendSelector.getSelectedBackend()

export const loginStorage = new BrowserLoginStorage(
  `HC-${process.env.REACT_APP_CLIENT_NAME}-${selectedBackend.NAME}`
)

const authRequestProvider = new EnvAuthRequestProvider(backendSelector)

export const loginFlow = new BrowserLoginFlow(loginStorage, authRequestProvider, backendSelector)

const clientConfig: ClientConfig = {
  backendSelector,
  authRequestProvider,
  loginStorage,
  loginFlow,

  // FIXME: Discuss removal, should just throw an exception where it happens
  onValidateJsonError: (error, axiosResponse) => {
    console.error('JSON Validation Error:', error, axiosResponse)
  }
}

export const api = createHttpClient(clientConfig, {})

export const publicApi = createPublicHttpClient({
  backendSelector
})

// FIXME: what about the other APIs?
// api.interceptors.response.use((response) => response)

// FIXME: set timeout?
// api.secureApi.defaults.timeout = 10;

const middleware = [thunk.withExtraArgument({api})]

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})

export const devStore = (initialState: Record<string, any>) =>
  createStore(
    resetEnhancer(appReducer()),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )

function configureStore(initialState = {}) {
  return devStore(initialState)
}

export const store = configureStore()

if (process.env.NODE_ENV !== 'production') {
  if ((module as any).hot) {
    ;(module as any).hot.accept('./App.reducer', () => {
      /* eslint-disable-next-line @typescript-eslint/no-var-requires */
      const nextReducer = require('./App.reducer').default
      store.replaceReducer(nextReducer(History) as Reducer)
    })
  }
}
