import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {QueryFunction} from 'react-query'

import {CreationChannel, CreationProduct, User} from '../response'
import {FetchUsersParameters, Product, StringParams} from '../types'

export const resetPassword = (api: AxiosInstance) => async (username) => {
  const body = {username}
  const response = await api.post('/users/resetPassword', body)
  return response.data
}

export interface CreateUserPayload {
  username: string
  isTermsApprovalRequired: boolean
  name: string
  eMail: string
  mobileNumber: string
  companyName: string
  creationChannel: CreationChannel
  creationProduct: CreationProduct
  defaultLocale: string
  requestedCustomerNumber?: string | null
  country: string
}

export const createUser = (api: AxiosInstance) => async (body: CreateUserPayload) => {
  const response = await api.post('/users', body)
  return response.data
}

export const getUserProfile =
  (api: AxiosInstance) =>
  async (
    userId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<User> => {
    const config = {params, ...axiosConfig}
    const response = await api.get<User>(`/users/${userId}`, config)
    return response.data
  }

export const changeUserPassword =
  (api: AxiosInstance) =>
  (params: StringParams = {}, axiosConfig?: AxiosRequestConfig): Promise<void> =>
    api.post('/users/changePassword', params, axiosConfig)

export const updateUserProfile =
  (api: AxiosInstance) =>
  async (
    userId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<User> => {
    const response = await api.put<User>(`/users/${userId}`, params, axiosConfig)
    return response.data
  }

export const getUsers =
  (api: AxiosInstance) =>
  async (params: FetchUsersParameters): Promise<AxiosResponse<Array<User>>> =>
    api.get('/users', {params})

export const inviteUser =
  (api: AxiosInstance) =>
  async (user: User, product: Product | null): Promise<AxiosResponse<any>> =>
    api.post('/users/invite', {user_id: user.id, product})
