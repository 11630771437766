import {ClientInstance} from '@hconnect/apiclient'
import {Action, combineReducers, Reducer} from 'redux'
import {ThunkDispatch} from 'redux-thunk'

import {loggedInUserReducer, LoggedInUserState} from './modules/LoggedInUser'
import {usersReducer, UsersState} from './modules/Users'

export const resetEnhancer = (rootReducer: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'RESET': {
      const newState = rootReducer(undefined, action)
      newState.router = state.router
      return newState
    }

    default:
      return rootReducer(state, action)
  }
}

export interface AppState {
  users: UsersState
  loggedInUser: LoggedInUserState
}

export type AppDispatch = ThunkDispatch<AppState, {api: ClientInstance}, Action>

const createAppReducer: () => Reducer<AppState> = () =>
  combineReducers({
    users: usersReducer,
    loggedInUser: loggedInUserReducer
  })

export default createAppReducer
