import {User} from '@hconnect/apiclient'

import {
  ADD_USER_TO_STORE,
  CLEAR_USERS,
  DELETE_USER_SUCCESS,
  FETCH_USERS_SUCCESS,
  FETCH_USER_SUCCESS,
  UsersAction
} from './Users.actions'

export interface UsersState {
  users: User[]
  totalCount: number
}

const initialState: UsersState = {
  users: [],
  totalCount: 0
}

export const usersReducer = (state: UsersState = initialState, action: UsersAction): UsersState => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS: {
      return {
        users: [...state.users, ...action.payload.users],
        totalCount: action.payload.totalCount
      }
    }

    case DELETE_USER_SUCCESS:
      return {
        users: state.users.filter(({id}) => id !== action.meta.userId),
        totalCount: state.totalCount - 1
      }

    case FETCH_USER_SUCCESS: {
      const usersWithoutUser = state.users.filter(({id}) => id !== action.payload.id)
      return {
        users: [...usersWithoutUser, action.payload],
        totalCount: state.totalCount + 1
      }
    }

    case CLEAR_USERS:
      return {users: [], totalCount: 0}

    case ADD_USER_TO_STORE:
      return {
        users: [...state.users, action.payload],
        totalCount: state.totalCount + 1
      }

    default:
      return state
  }
}
