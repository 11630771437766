import {User, PermissionType} from '@hconnect/apiclient'
import {Reducer} from 'redux'

import {
  FETCH_LOGGED_IN_USER_PERMISSIONS_FAILURE,
  FETCH_LOGGED_IN_USER_PERMISSIONS_REQUEST,
  FETCH_LOGGED_IN_USER_PERMISSIONS_SUCCESS,
  FETCH_LOGGED_IN_USER_PROFILE_FAILURE,
  FETCH_LOGGED_IN_USER_PROFILE_REQUEST,
  FETCH_LOGGED_IN_USER_PROFILE_SUCCESS,
  LoggedInUserAction,
  STORE_JWT_DATA
} from './LoggedInUser.actions'

interface DataScope {
  [dataScope: string]: string | string[] | undefined
}

export interface Permission {
  expiryDate: string | null
  dataScope: DataScope
  permissionType: PermissionType
}

export interface LoggedInUserState {
  isFetchingProfile: boolean
  profile: User | null
  isFetchingPermissions: boolean
  permissions: Permission[]
  error: Error | null
  user: {
    id: string
    name: string
    email: string
  } | null
}

export const initialState: LoggedInUserState = {
  isFetchingProfile: false,
  profile: null,
  isFetchingPermissions: false,
  permissions: [],
  error: null,
  user: null
}

export const loggedInUserReducer: Reducer<LoggedInUserState, LoggedInUserAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_LOGGED_IN_USER_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingProfile: true
      }

    case FETCH_LOGGED_IN_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingProfile: false,
        profile: action.payload
      }

    case FETCH_LOGGED_IN_USER_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingProfile: false,
        error: action.payload
      }

    case FETCH_LOGGED_IN_USER_PERMISSIONS_REQUEST:
      return {
        ...state,
        isFetchingPermissions: true
      }

    case FETCH_LOGGED_IN_USER_PERMISSIONS_SUCCESS: {
      const permissions = action.payload.map((permission) => ({
        permissionType: permission.permissionType,
        expiryDate: permission.expiryDate,
        dataScope: JSON.parse(permission.dataScope) as DataScope
      }))
      return {
        ...state,
        isFetchingPermissions: false,
        permissions
      }
    }

    case STORE_JWT_DATA: {
      return {
        ...state,
        user: action.payload
      }
    }

    case FETCH_LOGGED_IN_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        isFetchingPermissions: false,
        error: action.payload
      }

    default:
      return state
  }
}
