import {
  ClientInstance,
  getPermissionsByUserId,
  getUserProfile,
  Permission,
  User
} from '@hconnect/apiclient'
import {ThunkAction} from 'redux-thunk'

export const STORE_JWT_DATA = 'STORE_JWT_DATA'
export const FETCH_LOGGED_IN_USER_PROFILE_REQUEST = 'FETCH_LOGGED_IN_USER_PROFILE_REQUEST'
export const FETCH_LOGGED_IN_USER_PROFILE_SUCCESS = 'FETCH_LOGGED_IN_USER_PROFILE_SUCCESS'
export const FETCH_LOGGED_IN_USER_PROFILE_FAILURE = 'FETCH_LOGGED_IN_USER_PROFILE_FAILURE'
export const FETCH_LOGGED_IN_USER_PERMISSIONS_REQUEST = 'FETCH_LOGGED_IN_USER_PERMISSIONS_REQUEST'
export const FETCH_LOGGED_IN_USER_PERMISSIONS_SUCCESS = 'FETCH_LOGGED_IN_USER_PERMISSIONS_SUCCESS'
export const FETCH_LOGGED_IN_USER_PERMISSIONS_FAILURE = 'FETCH_LOGGED_IN_USER_PERMISSIONS_FAILURE'

interface FetchLoggedInUserProfileRequest {
  type: typeof FETCH_LOGGED_IN_USER_PROFILE_REQUEST
  meta: {
    userId: string
  }
}

interface FetchLoggedInUserProfileSuccess {
  type: typeof FETCH_LOGGED_IN_USER_PROFILE_SUCCESS
  payload: User
  meta: {
    userId: string
  }
}

interface FetchLoggedInUserProfileFailure {
  type: typeof FETCH_LOGGED_IN_USER_PROFILE_FAILURE
  payload: Error
  error: true
  meta: {
    userId: string
  }
}

interface FetchLoggedInUserPermissionsRequest {
  type: typeof FETCH_LOGGED_IN_USER_PERMISSIONS_REQUEST
  meta: {
    userId: string
  }
}

interface FetchLoggedInUserPermissionsSuccess {
  type: typeof FETCH_LOGGED_IN_USER_PERMISSIONS_SUCCESS
  payload: Permission[]
  meta: {
    userId: string
  }
}

interface FetchLoggedInUserPermissionsFailure {
  type: typeof FETCH_LOGGED_IN_USER_PERMISSIONS_FAILURE
  payload: Error
  error: true
  meta: {
    userId: string
  }
}

interface UserData {
  id: string
  email: string
  country: string
  name: string
}

interface StoreJWTData {
  type: typeof STORE_JWT_DATA
  payload: UserData
}

export type LoggedInUserAction =
  | StoreJWTData
  | FetchLoggedInUserProfileRequest
  | FetchLoggedInUserProfileSuccess
  | FetchLoggedInUserProfileFailure
  | FetchLoggedInUserPermissionsRequest
  | FetchLoggedInUserPermissionsSuccess
  | FetchLoggedInUserPermissionsFailure

const storeJWTDataAction = (userData: UserData): StoreJWTData => ({
  type: STORE_JWT_DATA,
  payload: userData
})

const fetchLoggedInUserProfileRequest = (userId: string): FetchLoggedInUserProfileRequest => ({
  type: FETCH_LOGGED_IN_USER_PROFILE_REQUEST,
  meta: {
    userId
  }
})

const fetchLoggedInUserProfileSuccess = (
  userId: string,
  data: User
): FetchLoggedInUserProfileSuccess => ({
  type: FETCH_LOGGED_IN_USER_PROFILE_SUCCESS,
  payload: data,
  meta: {
    userId
  }
})

const fetchLoggedInUserProfileFailure = (
  userId: string,
  error: Error
): FetchLoggedInUserProfileFailure => ({
  type: FETCH_LOGGED_IN_USER_PROFILE_FAILURE,
  payload: error,
  error: true,
  meta: {
    userId
  }
})

const fetchPermissionsRequest = (userId: string): FetchLoggedInUserPermissionsRequest => ({
  type: FETCH_LOGGED_IN_USER_PERMISSIONS_REQUEST,
  meta: {
    userId
  }
})

const fetchPermissionsSuccess = (
  userId: string,
  payload: Permission[]
): FetchLoggedInUserPermissionsSuccess => ({
  type: FETCH_LOGGED_IN_USER_PERMISSIONS_SUCCESS,
  payload,
  meta: {
    userId
  }
})

const fetchPermissionsFailure = (
  userId: string,
  error: Error
): FetchLoggedInUserPermissionsFailure => ({
  type: FETCH_LOGGED_IN_USER_PERMISSIONS_FAILURE,
  payload: error,
  error: true,
  meta: {
    userId
  }
})

type LoggedInUserThunkAction<R> = ThunkAction<R, any, {api: ClientInstance}, LoggedInUserAction>

export const storeJWTData =
  (payload: UserData): LoggedInUserThunkAction<void> =>
  (dispatch): void => {
    dispatch(storeJWTDataAction(payload))
  }

export const fetchLoggedInUserProfile =
  (userId: string): LoggedInUserThunkAction<Promise<void>> =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchLoggedInUserProfileRequest(userId))

    try {
      const user = await getUserProfile(api)(userId)
      dispatch(fetchLoggedInUserProfileSuccess(userId, user))
    } catch (error) {
      dispatch(fetchLoggedInUserProfileFailure(userId, error as Error))
    }
  }

export const fetchLoggedInUserPermissions =
  (userId: string): LoggedInUserThunkAction<Promise<void>> =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchPermissionsRequest(userId))

    try {
      const permissions = await getPermissionsByUserId(api)(userId)
      dispatch(fetchPermissionsSuccess(userId, permissions))
    } catch (error) {
      dispatch(fetchPermissionsFailure(userId, error as Error))
    }
  }
