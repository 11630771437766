import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Feature} from '../response'

export type FeatureTag =
  | 'Passwordless'
  | 'PushMessage'
  | 'SendEmail'
  | 'SendSms'
  | 'UserDataVerification'
  | 'loginflows'

export type FeatureName =
  | 'PasswordlessToggle'
  | 'DefaultPasswordlessProductEnabled'
  | 'LoginPasswordless'
  | 'LoginWithPassword'
  | 'UserDataVerificationToggle'
  | 'MaintenanceAnnoucementBanner'
  | 'OldLoginFlow'
  | 'LandingPageRequestAccess'
  | 'MarketMandatory'
  | 'OnSiteAnnouncementCountries'

type Scopes = Record<string, string | string[]>

const RESPONSE_OK = 200

export const getFeatures =
  (api: AxiosInstance) =>
  async (tag: FeatureTag, axiosConfig?: AxiosRequestConfig): Promise<Feature[]> => {
    const config = {
      ...axiosConfig
    }

    const response = await api.get<Feature[]>(`/features?tag=${tag}`, config)

    if (response.status !== RESPONSE_OK) {
      return []
    }

    response.data.forEach((feature) => {
      if (feature.warning) {
        console.warn(`feature ${feature.name} warning:  ${feature.warning}`)
      }
    })

    return response.data
  }

export const isScopesMatch = (scopes: Scopes, constraintScopes: Scopes): boolean => {
  for (const key in constraintScopes) {
    if (!constraintScopes[key]) {
      return false
    }

    const constraintScopeValues = Array.isArray(constraintScopes[key])
      ? (constraintScopes[key] as string[])
      : [constraintScopes[key] as string]
    if (constraintScopeValues.includes('*')) {
      continue
    }

    if (!Object.keys(scopes).includes(key) || !scopes[key]) {
      return false
    }

    const scopeValues = Array.isArray(scopes[key])
      ? (scopes[key] as string[])
      : [scopes[key] as string]
    if (scopeValues.includes('*')) {
      continue
    }

    for (let i = 0; i < constraintScopeValues.length; i++) {
      const constraintScopeValue = constraintScopeValues[i]
      if (!scopeValues.includes(constraintScopeValue)) {
        return false
      }
    }
  }
  return true
}

export const evaluateFeature = (feature: Feature, scopes: Scopes): boolean => {
  if (!feature) {
    console.warn('feature is required')
    return false
  }

  if (feature.enabled !== undefined) {
    return feature.enabled
  }

  if (feature.constraints === undefined || feature.constraints.length === 0) {
    return false
  }

  let isEnabled = false
  // Look for enabled
  feature.constraints
    .filter((constraint) => constraint.enabled)
    .forEach((constraint) => {
      if (isScopesMatch(scopes, JSON.parse(constraint.dataScope) as Scopes)) {
        isEnabled = true
      }
    })

  let isDisabled = false
  // Look for disabled
  feature.constraints
    .filter((constraint) => !constraint.enabled)
    .forEach((constraint) => {
      if (isScopesMatch(scopes, JSON.parse(constraint.dataScope) as Scopes)) {
        isDisabled = true
      }
    })

  return isEnabled && !isDisabled
}

export interface FeatureCheckParams {
  name: FeatureName
  tag: FeatureTag
  scopes: Scopes
}

export const isFeatureEnabled = (
  features: Feature[],
  scopes: Scopes,
  featureName: FeatureName
): boolean => {
  const feature = Array.isArray(features)
    ? features.find((feature) => feature.name.toLowerCase() === featureName.toLowerCase())
    : null

  if (!feature) {
    console.warn(`feature ${featureName} not found`)
    return false
  }

  if (feature.warning) {
    console.warn(`feature ${featureName} warning:  ${feature.warning}`)
  }

  return evaluateFeature(feature, scopes)
}
