import {ClientInstance, User} from '@hconnect/apiclient'
import {ThunkAction} from 'redux-thunk'

import {api} from '../App.store'

import {UsersState} from './Users'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const CLEAR_USERS = 'CLEAR_USERS'
export const ADD_USER_TO_STORE = 'ADD_USER_TO_STORE'

interface FetchUsersRequestAction {
  type: typeof FETCH_USERS_REQUEST
}

interface FetchUsersSuccessAction {
  type: typeof FETCH_USERS_SUCCESS
  payload: UsersState
}

interface FetchUsersFailureAction {
  type: typeof FETCH_USERS_FAILURE
  error: true
  payload: Error
}

interface FetchUserSuccessAction {
  type: typeof FETCH_USER_SUCCESS
  payload: User
}

interface CreateUserSuccessAction {
  type: typeof CREATE_USER_SUCCESS
  payload: User
}

interface DeleteUserSuccessAction {
  type: typeof DELETE_USER_SUCCESS
  meta: {
    userId: string
  }
}

interface AddUserToStoreAction {
  type: typeof ADD_USER_TO_STORE
  payload: User
}

interface ClearUsersAction {
  type: typeof CLEAR_USERS
}

export type UsersAction =
  | FetchUsersRequestAction
  | FetchUsersSuccessAction
  | FetchUsersFailureAction
  | FetchUserSuccessAction
  | CreateUserSuccessAction
  | DeleteUserSuccessAction
  | AddUserToStoreAction
  | ClearUsersAction

type UsersThunkAction<R> = ThunkAction<R, any, {api: ClientInstance}, UsersAction>

export const deleteUserSuccess = (userId: string): UsersAction => ({
  type: DELETE_USER_SUCCESS,
  meta: {
    userId
  }
})

// user is the JSON object returned from API when a new user was created (POST /users)
// TODO: ADD ASSERTION THAT THIS IS THE SAME FORMAT AS FOR FETCH USERS
export const addUserToStore = (user: User) => ({
  type: ADD_USER_TO_STORE,
  payload: user
})

const fetchUserSuccess = (user: User): UsersAction => ({
  type: FETCH_USER_SUCCESS,
  payload: user
})

export const fetchUser =
  (userId: string): UsersThunkAction<any> =>
  async (dispatch, getState, {api}) => {
    const response = await api.get(`/users/${encodeURIComponent(userId)}`)
    const {data: user} = response
    dispatch(fetchUserSuccess(user as User))
    return user
  }

export const createUser = async (payload: Partial<User>) => {
  const response = await api.post<User>('/users', payload)
  const {data: user} = response
  return user
}

export const updateUser = async (username: string, userInformation: Partial<User>) => {
  return api.patch<User>(`/users/${encodeURIComponent(username)}`, userInformation)
}
