import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {ValidateVerificationResponse, VerificationRequestResponse} from '../response'

const RESPONSE_OK = 200

export interface VerificationRequestParams {
  type: string
  redirect_uri: string
}

export const requestUserDataVerification =
  (api: AxiosInstance) =>
  async (
    params: VerificationRequestParams,
    axiosConfig?: AxiosRequestConfig
  ): Promise<VerificationRequestResponse> => {
    try {
      const config = {params, ...axiosConfig}

      const response = await api.get('/users/verify', config)

      if (response.status !== RESPONSE_OK) {
        return {
          type: 'error',
          error: {errorCode: response.status, message: JSON.stringify(response.data)}
        }
      }
      return {type: 'value', value: response.data}
    } catch (error) {
      return {
        type: 'error',
        error: error as {errorCode: number; message: string}
      }
    }
  }

export interface ValidateVerificationParams {
  start_token: string
  one_time_code: string
}

export const verifyUserData =
  (api: AxiosInstance) =>
  async (params: ValidateVerificationParams): Promise<ValidateVerificationResponse> => {
    try {
      const body = {
        ...params
      }

      const response = await api.post('/users/verify', body, {validateStatus: () => true})

      if (response.status !== RESPONSE_OK) {
        return {
          type: 'error',
          error: {errorCode: response.status, message: JSON.stringify(response.data)}
        }
      }

      return {type: 'value', value: response.data}
    } catch (error) {
      return {type: 'error', error: error as {errorCode: number; message: string}}
    }
  }
